import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useQuery } from "@apollo/client"
import { STUDY_IN_FRANCE, CONSULTATION_BLOCK } from "../components/graphql"
import parse from "html-react-parser"
import BounceIndicator from "react-activity/lib/Bounce"
import "react-activity/lib/Bounce/Bounce.css"
import { openPopupWidget } from "react-calendly"
// import { PopupWidget } from "react-calendly"

import AppoinmentBooking from "../components/dialog/appoinment"




const StudyInFrance = ({ data }) => {
  // const [showCalendly, setshowCalendly] = useState(false)
  const studyInFranceContent = useQuery(STUDY_IN_FRANCE)
  const consultationBlock = useQuery(CONSULTATION_BLOCK)

  const [isModalVisible, setisModalVisible] = React.useState(false);

  React.useEffect(() => {
    console.log("useEffect called")
  }, [])

  React.useEffect(() => {
		if (typeof window !== `undefined`) {
			if (typeof document !== `undefined`) {
			localStorage.setItem('lastRevistedPage',window.location.pathname)
			}
		}
	});
  
  var titleSplit = (studyInFranceContent.data
    ? studyInFranceContent.data.studyInFrance.title
    : ""
  ).split(" ")
  var title_org = `${titleSplit[0]} <br />`
  titleSplit.map((title, index) => {
    if (index !== 0) {
      title_org = title_org + title + " "
    }
  })

  var bannerImage = studyInFranceContent.data
    ? process.env.BACKEND_URL +
    studyInFranceContent.data.studyInFrance.video.url
    : undefined

  var consultationImage = consultationBlock.data
    ? process.env.BACKEND_URL +
    consultationBlock.data.consultationBlock.thumbnailImage.url
    : undefined

  

  const renderConsultationBlock = () => {
    return (
      <div className="row">
        <div className="col-md-6 text pb-2">
          <div className="title">
            {consultationBlock.data.consultationBlock.title1} <br />
            {consultationBlock.data.consultationBlock.title2}
          </div>
          <p>{consultationBlock.data.consultationBlock.shortDescription}</p>
          {process.env.FILLOUT_ENQUIRY_FORM?
            // <button
            //   // className="talk"
            //   // onClick={(e) => {
            //   //   // e.preventDefault()
            //   //   console.log('clicked')
            //   //   setisModalVisible(true)
            //   //   console.log(isModalVisible)
  
            //   //   //calendlyAction()
            //   // }}
            // >
            // </button>
            <div className="talk">
              <Link to= {`${process.env.FILLOUT_ENQUIRY_FORM}`} className="text-dark"  
              >
              {consultationBlock.data.consultationBlock.buttonName}
              </Link>
            </div>
          :null}
        </div>
        <div className="col-md-6 img">
          <img
            src={consultationImage ? consultationImage : ""}
            alt={consultationBlock.data.consultationBlock.altthumbnailName}
          />
        </div>
      </div>
    )
  }

  const calendlyAction = () => {
    return (
      <div>
        {openPopupWidget({
          url: "https://calendly.com/dimensionsfrance/bonjour",
          prefill: null,
          pageSettings: null,
          utm: null,
        })}
      </div>
    )
  }

  const { allStrapiStudyInFrance, allStrapiHomePage } = data
  const seoTitle =
    allStrapiStudyInFrance !== null
      ? allStrapiStudyInFrance.edges[0].node.title
      : ""
  const seoMetaDescription =
    allStrapiStudyInFrance !== null
      ? allStrapiStudyInFrance.edges[0].node.metaDescription
      : ""
  const seoImage =
    allStrapiHomePage !== null
      ? allStrapiHomePage.edges[0].node.metaImage
        ? allStrapiHomePage.edges[0].node.metaImage.url
        : ""
      : ""
  if (studyInFranceContent.loading)
    return (
      <>
        <SEO
          title={seoTitle}
          description={seoMetaDescription}
          image={seoImage}
        />
        <div className={"centered"}>
          <BounceIndicator size={25} color={"#0055a4"} />
        </div>
      </>
    )
  if (studyInFranceContent.error) return `Error! ${studyInFranceContent.error}`

  return (
    <>
      <SEO title={seoTitle} description={seoMetaDescription} image={seoImage} />
      <Layout setTitle={studyInFranceContent.data.studyInFrance.title}  tag="study-in-france">
        {studyInFranceContent.data ? (
          <>
            <div className="main-content">
              {console.log(
                "$$$$$$$$$$$$$$$$$$$$$$$$",
                studyInFranceContent.data
              )}
              {/* banner */}
              <section className="banner_section">
                <div
                  className="home-banner"
                  style={{
                    backgroundImage: `url(${bannerImage})`,
                    backgroundAttachment: "fixed",
                  }}
                >
                  <div className="overlay"></div>
                  {/* <img 
                  src={bannerImage ? bannerImage : ""}  
                  alt={studyInFranceContent.data.studyInFrance.altVideoName} loading="lazy"/> */}
                </div>
              </section>
              {/* /banner */}
              {/* main */}
              <main className="home">
                <div className="main-content">
                  <div className="container">
                    {/* study in France */}
                    <section>
                      <div className="study_france">
                        <div className="left_title">
                          {studyInFranceContent.data ? parse(title_org) : ""}
                        </div>
                        <div className="right_content">
                          {studyInFranceContent.data
                            ? parse(
                              studyInFranceContent.data.studyInFrance
                                .description
                            )
                            : ""}
                        </div>
                      </div>
                    </section>
                    {/* /study in France */}


                    {/* study in France cards */}
                    <section className="ptop pb100">
                      <div className="study_france_cards">
                        {console.log(
                          "listing length",
                          studyInFranceContent.data
                            ? studyInFranceContent.data.studyInFrance
                              .studyListing.length
                            : "444"
                        )}
                        {studyInFranceContent.data
                          ? studyInFranceContent.data.studyInFrance.studyListing.map(
                            (content, index) => (
                              <div key={index} className="card-list">
                                <div className="image">
                                  <img
                                    src={
                                      content.thumbnailImage
                                        ? process.env.BACKEND_URL +
                                        content.thumbnailImage.url
                                        : undefined
                                    }
                                    alt={content.altThumbnailName}
                                  />
                                </div>
                                <div className="content">
                                  <div className="number">
                                    {content.position < 10
                                      ? "0" + content.position
                                      : content.position}
                                  </div>
                                  <Link
                                    to={`/study-in-france/${content.url}/`}
                                    // to={`/study-in-france-detail`}
                                    // state={{id: content.id}}
                                    className="title"
                                    style={{
                                      textDecoration: "none",
                                    }}
                                  >
                                    {content.listingTitle}
                                  </Link>
                                  <div className="detail">
                                    {parse(content.shortDescription)}
                                  </div>
                                </div>
                              </div>
                            )
                          )
                          : ""}
                      </div>
                    </section>
                    {/* /study in France cards */}

                    <AppoinmentBooking isOpen={isModalVisible} handleClose={()=>setisModalVisible(false)} />


                    {/* Consultation */}
                    <section className="ptop">
                      <div className="consultation pb100">
                        {consultationBlock.data
                          ? renderConsultationBlock()
                          : ""}
                      </div>
                    </section>
                    {/* /Consultation */}
                  </div>
                  {/* /container */}
                </div>
              </main>
              {/* /main */}
            </div>
          </>
        ) : null}
      </Layout>
    </>
  )
}

export default StudyInFrance

export const query = graphql`
  query {
    allStrapiStudyInFrance {
      edges {
        node {
          title
          metaDescription
        }
      }
    }
    allStrapiHomePage {
      edges {
        node {
          metaImage {
            url
          }
        }
      }
    }
  }
`
